import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useTradeItems } from 'content-queries/trade/trade'
import fireEvent from 'context/tracking/events'
import {
  ContentColumnSplit,
  Header,
  MainBanner,
  MainContent,
  TrustedMedia,
} from 'mx/components/new'
import Layout from 'mx/layout'

const TradePage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Trade Program',
    })
  }, [])

  const { data } = useTradeItems()

  const [
    {
      node: {
        sectionModules: [
          hero,
          performance,
          working,
          experience,
          cabinetry,
          media,
          nextLevel,
        ],
      },
    },
  ] = data

  return (
    <Layout hideBanner>
      <Header data={hero} />
      <MainContent
        data={performance}
        inline
        dark
        classname={tw`mt-20 lg:mt-32 !bg-brown-medium`}
      />
      <ContentColumnSplit data={working} classname={tw`mt-20 lg:mt-32`} />
      <MainContent
        data={experience}
        dark
        inline
        classname={tw`mt-20 lg:mt-32`}
      />
      <MainContent
        data={cabinetry}
        inline
        dark
        classname={tw`mt-20 lg:mt-32 !bg-brown-medium`}
      />
      <TrustedMedia data={media} classname={tw`mt-20 lg:mt-32`} />
      <MainBanner data={nextLevel} classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}
export default TradePage

export const Head = () => {
  const { data } = useTradeItems()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/trade"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
